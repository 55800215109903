import CodeHighlighter from "../../../../components/CodeHighlighter"

const SLC = () => {

  const string =
    `const overview = {
  degree: 'School Leaving Certificate Examination (SLC)'
  course: 'Basic School Education',
  institude: 'Nava Jyoti English Secandary School',
  completedYear: 2014,
  degreeAwardedBy: 'National Examination Board'
}

const modules = [
  'Comp. English',
  'Comp. Nepali',
  'Comp. Mathematics',
  'Comp. Science',
  'Comp. Social Studies'
  'Comp. Health, Population and Environment Education'
  'Opt. I Additional Mathematics'
  'Opt. II Computer Science'
}
`

  return (
    <CodeHighlighter string={string} />
  )
}

export default SLC