import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../@types/theme';
import ProjectSideBar from '../../components/Sidebar/ProjectSidebar';
import ProjectList from './ProjectList';

const StyledProjectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 500px) {
    flex-direction: column;
    overflow: auto;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const StyledBlockHeader = styled.div`
  height: 40px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};

  @media (max-width: 500px) {
    height: auto;
    width: 100%;
    border-top: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
    border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
    padding: 10px 20px;
  }
`;

const StyledBlockTitle = styled.span`
  height: 100%;
  width: 100%;
  display: flex;
  padding-right: 30px;
  align-items: center;
`;

const StyledBlockCloseIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    display: none;
  }
`;

const StyledContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  @media (max-width: 500px) {
    overflow-y: visible;
  }
`;
export interface IStacksData {
  name: string;
  icon: string;
  isChecked: boolean;
}

const Projects = () => {
  const stacksData: IStacksData[] = [
    {
      name: 'HTML',
      icon: 'ri-html5-fill',
      isChecked: false,
    },
    {
      name: 'CSS',
      icon: 'ri-css3-fill',
      isChecked: false,
    },
    {
      name: 'JavaScript',
      icon: 'ri-javascript-fill',
      isChecked: false,
    },
    {
      name: 'React',
      icon: 'ri-reactjs-fill',
      isChecked: true,
    },
    {
      name: 'Angular',
      icon: 'ri-angularjs-fill',
      isChecked: true,
    },
    {
      name: 'Vue.js',
      icon: 'ri-vuejs-fill',
      isChecked: true,
    },
    {
      name: 'Node.js',
      icon: 'ri-nodejs-fill',
      isChecked: false,
    },
    {
      name: 'Express',
      icon: 'ri-terminal-box-fill',
      isChecked: false,
    },
    {
      name: 'MongoDB',
      icon: 'ri-terminal-box-fill',
      isChecked: false,
    },
  ];

  const [stacks, setStacks] = useState<IStacksData[]>(stacksData);
  const checkedStacks = useMemo(() => stacks.filter(stack => stack.isChecked).map(stack => stack.name), [stacks]);

  return (
    <StyledProjectWrapper>
      <ProjectSideBar stacks={stacks} setStacks={setStacks} />
      <StyledContentWrapper>
        <StyledBlockHeader>
          <StyledBlockTitle>{checkedStacks.join('; ') || 'All Stacks'}</StyledBlockTitle>
          <StyledBlockCloseIcon>
            <i className="ri-close-fill"></i>
          </StyledBlockCloseIcon>
        </StyledBlockHeader>
        <StyledContent>
          <ProjectList checkedStacks={checkedStacks} />
        </StyledContent>
      </StyledContentWrapper>
    </StyledProjectWrapper>
  );
};

export default Projects;
