import CodeHighlighter from '../../../../components/CodeHighlighter'

const GreenComputing = () => {
  const string =
    `const overview = {
  institution: 'Green Computing Nepal',
  startDate: March 2019,
  endDate: Nov 2019,
  jobTitle: 'Software Developer',
  designation: 'Intern/Jr. Developer',
}

const keyPoints = [
  'Learned software engineering process improvements and best practices.',
  'Planned website development, converting mockups into usable web presence with HTML, JavaScript, PHP and Laravel.',
  'Learned to customized the themes in WordPress CMS and Moodle LMS.',
]
`

  return (
    <CodeHighlighter string={string} />
  )
}

export default GreenComputing