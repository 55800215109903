import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeProps } from '../../@types/theme';

const StyledNavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 100%;
  height: 100%;
    a{
      text-decoration: none;
      border-bottom: transparent 3px solid;
      color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
      &:visited, &:active{
        text-decoration: none;
        color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
      }
      &:hover{
        color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
      }
      &.activeStyle {
      color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
      border-bottom: 3px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.accent1};
    }
  }

  @media(max-width: 500px) {
    display: none;
  }
`;

const StyledMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
`;

const StyledBrand = styled.span`
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  flex-shrink: 0;
  width: 240px;
  height: 100%;
`;

const StyledMenuItem = styled.li`
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  flex-shrink: 0;
  height: 100%;
  padding-top: 3px;
`;

const StyledMenuContact = styled.span`
  border-left: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  flex-shrink: 0;
  height: 100%;
  padding-top: 3px;
`;

const StyledLink = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0 30px',
  textDecoration: 'none',
  cursor: 'pointer'
};

const StyledNavBarMobile = styled.nav`
  height: 100%;
  display: none;
  a{
      text-decoration: none;
      color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
      &:visited, &:active{
        text-decoration: none;
        color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
      }
      &:hover{
        color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
      }
      &.activeStyle {
      color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
    }
  }
  @media(max-width: 500px) {
    display: block;
  }
  
`
const StyledNavBarHeaderMobile = styled.nav`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MobileMenuContainer = styled.div`
  border-top:  1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  border-bottom:  1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  position: absolute;
  z-index: 100;
  height: calc(100% - 122px);
  width: calc(100% - 32px);
  padding: 2px;
  background: ${({ theme }: { theme: ThemeProps }) => theme.colors.primary1};
`

const MobileMenuItem = styled.div`
  border-bottom:  1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
`
const StyledLinkMobile = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '15px',
  textDecoration: 'none',
  cursor: 'pointer'
};

const NavBar = () => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (

    <>
      <StyledNavBar>
        <StyledMenu>
          <StyledBrand>
            <span style={StyledLink}>bikash-bhattarai</span>
          </StyledBrand>
          <StyledMenuItem>
            <NavLink to="/" style={StyledLink} className={({ isActive }) =>
              isActive ? 'activeStyle' : undefined}
            >_hello</NavLink>
          </StyledMenuItem>
          <StyledMenuItem>
            <NavLink to="/about" style={StyledLink} className={({ isActive }) =>
              isActive ? 'activeStyle' : undefined}>_about</NavLink>
          </StyledMenuItem>
          <StyledMenuItem>
            <NavLink to="/projects" style={StyledLink} className={({ isActive }) =>
              isActive ? 'activeStyle' : undefined}>_projects</NavLink>
          </StyledMenuItem>
        </StyledMenu>
        <StyledMenuContact>
          <NavLink to="/contact" style={StyledLink} className={({ isActive }) =>
            isActive ? 'activeStyle' : undefined}>_contact</NavLink>
        </StyledMenuContact>
      </StyledNavBar>

      {/* mobile view */}
      <StyledNavBarMobile>
        <StyledNavBarHeaderMobile>
          <span style={StyledLinkMobile}>bikash-bhattarai</span>
          <span style={StyledLinkMobile} role="button" onClick={toggleMenu}>
            {isOpen ? (
              <i className="ri-close-line"></i>
            ) : (
              <i className="ri-menu-line"></i>
            )}
          </span>
        </StyledNavBarHeaderMobile>

        {isOpen && (
          <MobileMenuContainer>
            <MobileMenuItem>
              <NavLink to="/" onClick={toggleMenu} style={StyledLinkMobile} className={({ isActive }) =>
                isActive ? 'activeStyle' : undefined}
              >_hello</NavLink>
            </MobileMenuItem>
            <MobileMenuItem>
              <NavLink to="/about" onClick={toggleMenu} style={StyledLinkMobile} className={({ isActive }) =>
                isActive ? 'activeStyle' : undefined}>_about</NavLink>
            </MobileMenuItem>
            <MobileMenuItem>
              <NavLink to="/projects" onClick={toggleMenu} style={StyledLinkMobile} className={({ isActive }) =>
                isActive ? 'activeStyle' : undefined}>_projects</NavLink>
            </MobileMenuItem>
            <MobileMenuItem>
              <NavLink to="/contact" onClick={toggleMenu} style={StyledLinkMobile} className={({ isActive }) =>
                isActive ? 'activeStyle' : undefined}>_contact</NavLink>
            </MobileMenuItem>
          </MobileMenuContainer>
        )}
      </StyledNavBarMobile>
    </>
  )
}

export default NavBar