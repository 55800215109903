import React from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../../@types/theme';
import ContactSideBar from '../../components/Sidebar/ContactSidebar';
import ContactContent from './Content';

const StyledContactWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 500px){
    flex-direction: column;
    overflow: auto;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: auto;

  @media (max-width: 500px){
    flex-direction: column;
  }
`;

const StyledBlockHeader = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};

  @media (max-width: 500px){
    display: none;
  }
`

const StyledBlockTitle = styled.span`
  height: 100%;
  display: flex;
  padding-right: 30px;
  align-items: center;
  max-width: 100%;
  min-width: 150px;
`

const StyledBlockCloseIcon = styled.div`
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
  font-size: 20px;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
`

const StyledContent = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 5px 20px;
`

const Contact = () => {
  return (
    <StyledContactWrapper>
      <ContactSideBar />
      <StyledContentWrapper>
        <StyledBlockHeader>
          <StyledBlockTitle>
            Contact
          </StyledBlockTitle>
          <StyledBlockCloseIcon>
            <i className="ri-close-fill"></i>
          </StyledBlockCloseIcon>
        </StyledBlockHeader>
        <StyledContent>
          <ContactContent />
        </StyledContent>
      </StyledContentWrapper>
    </StyledContactWrapper>
  )
}

export default Contact