import React, { useState } from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../../@types/theme';
import AboutSideBar from '../../components/Sidebar/AboutSidebar';
import LeftContent from './LeftContent';
import RightContent from './RightContent';

const StyledAboutWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 500px){
    flex-direction: column;
    overflow: auto;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  
  @media (max-width: 500px){
    flex-direction: column;
  }
`;

const StyledAboutLeft = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  width: 50%;

  @media (max-width: 500px){
    width: 100%;
  }
`;

const StyledAboutRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 500px){
    width: 100%;
  }
`;

const StyledBlockHeader = styled.div`
  height: 40px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines}; 

  @media (max-width: 500px){
    width: 100%;
    border-top: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
    border-bottom: none;
    padding-left: 30px;
  }
`

const StyledBlockTitle = styled.span`
  height: 100%;
  padding-right: 30px;
  display: flex;
  align-items: center;
  min-width: 150px;

  @media (max-width: 500px){
    min-width: 100%;
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};

    ::before{
      content: '#';
      padding-right: 8px;
      color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4}; 
    }
  }
`

const StyledBlockCloseIcon = styled.div`
  height: 100%;
  display: flex;
  padding: 0 10px;
  align-items: center;
  font-size: 20px;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};

  @media (max-width: 500px){
    display: none;
  }
`

const StyledLeftContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const StyledRightContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const About = () => {

  const [activeNav, setActiveNav] = useState<string>('')

  return (
    <StyledAboutWrapper>
      <AboutSideBar setActiveNav={setActiveNav} />
      <StyledContentWrapper>
        <StyledAboutLeft>
          <StyledBlockHeader>
            <StyledBlockTitle>
              {activeNav}
            </StyledBlockTitle>
            <StyledBlockCloseIcon>
              <i className="ri-close-fill"></i>
            </StyledBlockCloseIcon>
          </StyledBlockHeader>
          <StyledLeftContent>
            <LeftContent />
          </StyledLeftContent>
        </StyledAboutLeft>
        <StyledAboutRight>
          <StyledBlockHeader ></StyledBlockHeader>
          <StyledRightContent>
            <RightContent />
          </StyledRightContent>
        </StyledAboutRight>
      </StyledContentWrapper>
    </StyledAboutWrapper >
  )
}

export default About