import { ThemeProps } from "../@types/theme";
import colors from "../constants/colors";

const theme: ThemeProps = {
  colors: {
    body: colors.body,
    primary1: colors.primary1,
    primary2: colors.primary2,
    secondary1: colors.secondary1,
    secondary2: colors.secondary2,
    secondary3: colors.secondary3,
    secondary4: colors.secondary4,
    accent1: colors.accent1,
    accent2: colors.accent2,
    accent3: colors.accent3,
    accent4: colors.accent4,
    lines: colors.lines,
    gradient1: colors.gradient1,
    gradient2: colors.gradient2,
    btnPrimary: colors.btnPrimary,
    btnPrimaryHover: colors.btnPrimaryHover,
    btnPrimaryText: colors.btnPrimaryText,
    btnDefault: colors.btnDefault,
    btnDefaultHover: colors.btnDefaultHover,
    btnDefaultText: colors.btnDefaultText,
    btnGhost: colors.btnGhost,
    btnGhostHover: colors.btnGhostHover,
    btnGhostText: colors.btnGhostText,
    socialMedia: colors.socialMedia,
    socialMediaHover: colors.socialMediaHover
  }
}

export default theme