import React, { PropsWithChildren } from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../@types/theme';

const StyledFooter = styled.footer`
  height: 40px;
  flex-shrink: 0;
  border-top: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
`;

const Footer = (props: PropsWithChildren) => {
  return (
    <StyledFooter>
      {props.children}
    </StyledFooter>
  )
}

export default Footer