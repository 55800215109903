import React, { useState, useRef, useEffect } from 'react';
import { FoodContainer, StyledButton, StyledCanvas, StyledCanvasWrapper, StyledFood, StyledFoodEaten, StyledFoodWrapper, StyledGameOver, StyledGameOverWrapper, StyledInstructionTextWrapper, StyledInstructions, StyledInstructionsWrapper, StyledPlayAgainButton, StyledPlayAgainWrapper, StyledScrew, StyledWrapper } from './Styled';

const canvasHeight = 440
const canvasWidth = 340
const gridSize = 10; // size of the grid in pixels
const snakeMovementInterval = 162; // interval at which the snake moves in milliseconds
const winningPoint = 10 // points required to win the game

// the possible directions the snake can move
enum Direction {
  Up = 'UP',
  Down = 'DOWN',
  Left = 'LEFT',
  Right = 'RIGHT',
}

// the initial state of the game
const initialState = {
  // the current direction of the snake
  direction: Direction.Up,
  // the position of each cell of the snake on the grid
  snakePositions: [
    { x: 18, y: 19 },
    { x: 18, y: 20 },
    { x: 18, y: 21 },
  ],
  // the position of the food on the grid
  foodPosition: { x: 15, y: 10 },
  // whether the game is started
  notStarted: true,
  // whether the game is over
  gameOver: false,
  // winning flag
  win: false,
  // the score
  score: 0,
};

function SnakeGame() {
  // the state of the game
  const [state, setState] = useState(initialState);
  // a reference to the canvas element
  const canvasRef: any = useRef<HTMLCanvasElement>(null);
  // a reference to the setInterval function, which we will use to start and stop the game loop
  const intervalRef: any = useRef<number>();

  // this function is called when the component is mounted and when the game is reset
  useEffect(() => {
    // get the canvas context
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        // clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // draw the food
        // third layer of the food
        ctx.fillStyle = '#43d9ac4c';
        ctx.beginPath();
        ctx.arc(
          (state.foodPosition.x * gridSize) + (gridSize / 2),
          (state.foodPosition.y * gridSize) + (gridSize / 2),
          (gridSize + 12) / 2,
          0, 360
        );
        ctx.fill();

        // second layer of the food
        ctx.fillStyle = '#43d9aca0';
        ctx.beginPath();
        ctx.arc(
          (state.foodPosition.x * gridSize) + (gridSize / 2),
          (state.foodPosition.y * gridSize) + (gridSize / 2),
          (gridSize + 6) / 2,
          0, 360
        );
        ctx.fill();

        // first layer of the food
        ctx.fillStyle = '#43d9ac';
        ctx.beginPath();
        ctx.arc(
          (state.foodPosition.x * gridSize) + (gridSize / 2),
          (state.foodPosition.y * gridSize) + (gridSize / 2),
          gridSize / 2,
          0, 360
        );
        ctx.fill();

        // Create gradient
        var grd = ctx.createLinearGradient(0, 0, 10, 10);
        grd.addColorStop(0, '#43D9AD');
        grd.addColorStop(1, '#43D9AD');

        // draw the snake
        ctx.fillStyle = grd;
        state.snakePositions.forEach((position) => {
          ctx.fillRect(
            position.x * gridSize,
            position.y * gridSize,
            gridSize,
            gridSize
          );
        });
      }
    }

    // start the game loop
    if (!state.notStarted) {
      intervalRef.current = window.setInterval(gameLoop, snakeMovementInterval);
    }

    function spacePress(event: KeyboardEvent) {
      if (event.code === 'Space') {
        //whatever you want to do when space is pressed
        if (state.gameOver || state.notStarted || state.win) {
          resetGame();
        }
      }
    }

    window.addEventListener('keyup', spacePress)

    // clean up the interval when the component is unmounted or the game is reset
    return () => {
      window.clearInterval(intervalRef.current);
      window.removeEventListener('keyup', spacePress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameLoop]);

  // handle key press events
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      // update the direction based on the key that was pressed
      if (event.key === 'ArrowUp') {
        setState((prevState) => ({ ...prevState, direction: Direction.Up }));
      } else if (event.key === 'ArrowDown') {
        setState((prevState) => ({ ...prevState, direction: Direction.Down }));
      } else if (event.key === 'ArrowLeft') {
        setState((prevState) => ({ ...prevState, direction: Direction.Left }));
      } else if (event.key === 'ArrowRight') {
        setState((prevState) => ({ ...prevState, direction: Direction.Right }));
      }
    }

    // add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // this function is called at the specified interval to update the game state
  function gameLoop() {
    // prevent the state update in the game is over or winned.
    if (state.gameOver || state.win) {
      return;
    }
    // get the next position of the snake's head
    const nextPosition = getNextPosition(state.snakePositions[0], state.direction);

    // check if the snake has collided with the wall or itself
    if (
      nextPosition.x < 0 ||
      nextPosition.x >= canvasRef.current?.width / gridSize ||
      nextPosition.y < 0 ||
      nextPosition.y >= canvasRef.current?.height / gridSize ||
      state.snakePositions.some((position) => position.x === nextPosition.x && position.y === nextPosition.y)
    ) {
      // stop the game loop
      window.clearInterval(intervalRef.current);
      // set the game over flag
      setState((prevState) => ({ ...prevState, gameOver: true }));
      return;
    }

    // check if the snake has eaten the food
    let newSnakePositions = [nextPosition, ...state.snakePositions];
    let newFoodPosition = state.foodPosition;
    let newScore = state.score;
    if (nextPosition.x === state.foodPosition.x && nextPosition.y === state.foodPosition.y) {
      // generate a new food position
      newFoodPosition = generateFoodPosition(newSnakePositions);
      // add a new cell to the end of the snake's body
      newSnakePositions = [nextPosition, ...newSnakePositions];
      // increase the score
      newScore++;
    } else {
      // remove the last cell of the snake's body if it hasn't eaten the food
      newSnakePositions.pop();
    }


    // check if the score is 10 (winning condition)
    if (
      newScore === winningPoint
    ) {
      // stop the game loop
      window.clearInterval(intervalRef.current);
      // set the game win flag
      setState((prevState) => ({ ...prevState, score: newScore, win: true }));
      return;
    }

    // update the state with the new snake positions, food position, and score
    setState((prevState) => ({
      ...prevState,
      snakePositions: newSnakePositions,
      foodPosition: newFoodPosition,
      score: newScore,
    }));
  }

  // this function returns the next position of the snake's head based on the current position and direction
  function getNextPosition(position: { x: number; y: number }, direction: Direction) {
    if (!state.gameOver && !state.win) {
      switch (direction) {
        case Direction.Up:
          return { x: position.x, y: position.y - 1 };
        case Direction.Down:
          return { x: position.x, y: position.y + 1 };
        case Direction.Left:
          return { x: position.x - 1, y: position.y };
        case Direction.Right:
          return { x: position.x + 1, y: position.y };
        default:
          return position;
      }
    }
    return position
  }

  // this function generates a new food position that doesn't overlap with the snake
  function generateFoodPosition(snakePositions: Array<{ x: number; y: number }>) {
    let position: { x: number; y: number } | undefined;
    // eslint-disable-next-line no-loop-func
    while (position === undefined || snakePositions.some((p) => p.x === position?.x && p.y === position?.y)) {
      position = {
        x: Math.floor(Math.random() * (canvasRef.current?.width / gridSize)),
        y: Math.floor(Math.random() * (canvasRef.current?.height / gridSize)),
      };
    }
    return position;
  }

  function resetGame() {
    // reset the state to the initial state
    setState({ ...initialState, notStarted: false });
    // clear the interval to stop the game loop
    window.clearInterval(intervalRef.current);
    // start the game loop again
    intervalRef.current = window.setInterval(gameLoop, snakeMovementInterval);
  }


  return (
    <StyledWrapper>
      <StyledCanvasWrapper>
        <StyledCanvas ref={canvasRef} height={canvasHeight} width={canvasWidth} />
        {state.notStarted &&
          <StyledGameOverWrapper>
            <StyledGameOver>
              PLAY NOW
            </StyledGameOver>
            <StyledPlayAgainWrapper>
              <StyledPlayAgainButton onClick={resetGame}>
                Start
              </StyledPlayAgainButton>
            </StyledPlayAgainWrapper>
          </StyledGameOverWrapper>
        }
        {state.gameOver &&
          <StyledGameOverWrapper>
            <StyledGameOver>
              GAME OVER!
            </StyledGameOver>
            <StyledPlayAgainWrapper>
              <StyledPlayAgainButton onClick={resetGame}>
                Play Again
              </StyledPlayAgainButton>
            </StyledPlayAgainWrapper>
          </StyledGameOverWrapper>
        }
        {state.win &&
          <StyledGameOverWrapper>
            <StyledGameOver>
              WELL DONE!
            </StyledGameOver>
            <StyledPlayAgainWrapper>
              <StyledPlayAgainButton onClick={resetGame}>
                Play Again
              </StyledPlayAgainButton>
            </StyledPlayAgainWrapper>
          </StyledGameOverWrapper>
        }
      </StyledCanvasWrapper>
      <StyledInstructionsWrapper>
        <StyledInstructions>
          <StyledInstructionTextWrapper>
            <p>
              {`// use keyboard`}
            </p>
            <p>
              {`// arrows to play`}
            </p>
          </StyledInstructionTextWrapper>

          <StyledButton>
            ▲
          </StyledButton>
          <div>
            <StyledButton>
              ◀
            </StyledButton>
            <StyledButton>
              ▼
            </StyledButton>
            <StyledButton>
              ▶
            </StyledButton>
          </div>
        </StyledInstructions>

        <StyledFoodWrapper>
          <p>
            {`// food left`}
          </p>
          <FoodContainer>
            {Array.from(Array((winningPoint - state.score)).keys()).map((_n) => (
              <StyledFood />
            ))}
            {Array.from(Array(state.score).keys()).map((_n) => (
              <StyledFoodEaten />
            ))}
          </FoodContainer>
        </StyledFoodWrapper>

      </StyledInstructionsWrapper>

      {/* Screws */}
      <StyledScrew className='top-left'>
        <div className='line-one'></div>
        <div className='line-two'></div>
      </StyledScrew>
      <StyledScrew className='top-right'>
        <div className='line-one'></div>
        <div className='line-two'></div>
      </StyledScrew>
      <StyledScrew className='bottom-left'>
        <div className='line-one'></div>
        <div className='line-two'></div>
      </StyledScrew>
      <StyledScrew className='bottom-right'>
        <div className='line-one'></div>
        <div className='line-two'></div>
      </StyledScrew>
    </StyledWrapper>
  );

}

export default SnakeGame;
