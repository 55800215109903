import { Route, Routes } from 'react-router-dom'
import About from '../pages/About'
import Introduction from '../pages/About/LeftContent/Bio/Introduction'
import Bachelor from '../pages/About/LeftContent/Education/Bachelor'
import PlusTwo from '../pages/About/LeftContent/Education/PlusTwo'
import Abt from '../pages/About/LeftContent/Work/Abt'
import GreenComputing from '../pages/About/LeftContent/Work/GreenComputing'
import InfoDev from '../pages/About/LeftContent/Work/InfoDev'
import Contact from '../pages/Contact'
import Home from '../pages/Home'
import Projects from '../pages/Projects'
import SLC from '../pages/About/LeftContent/Education/SLC'

const Router = () => {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="about" element={<About />}>
        <Route path="bio/introduction" element={<Introduction />} />
        <Route path="education/bachelor" element={<Bachelor />} />
        <Route path="education/high-school" element={<PlusTwo />} />
        <Route path="education/slc" element={<SLC />} />
        <Route path="work/info-dev" element={<InfoDev />} />
        <Route path="work/abt" element={<Abt />} />
        <Route path="work/green-computing" element={<GreenComputing />} />
      </Route>
      <Route path="projects" element={<Projects />} />
      <Route path="contact" element={<Contact />} />
    </Routes>
  )
}

export default Router