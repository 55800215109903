export interface IProjectItem {
  title: string;
  subTitle: string;
  description: string;
  technologies: string[];
  codeLink: string;
  demoLink: string;
}

export const projects: IProjectItem[] = [
  {
    title: 'Portfolio Website',
    subTitle: 'Personal Portfolio',
    description: 'A personal portfolio website to showcase my skills, projects, and experience.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'React'],
    codeLink: 'https://github.com/username/portfolio-website',
    demoLink: 'https://username.github.io/portfolio-website',
  },
  {
    title: 'E-commerce Platform',
    subTitle: 'Online Store',
    description: 'An e-commerce platform for buying and selling products with user authentication and payment gateway integration.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'React', 'Node.js', 'Express', 'MongoDB'],
    codeLink: 'https://github.com/username/e-commerce-platform',
    demoLink: 'https://username.github.io/e-commerce-platform',
  },
  {
    title: 'Weather App',
    subTitle: 'Weather Forecasting',
    description: 'A weather forecasting app that provides current weather data and forecasts for different locations.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'React'],
    codeLink: 'https://github.com/username/weather-app',
    demoLink: 'https://username.github.io/weather-app',
  },
  {
    title: 'Task Manager',
    subTitle: 'To-Do List Application',
    description: 'A task management app to help users organize their tasks and to-dos with due dates and priorities.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'Vue.js'],
    codeLink: 'https://github.com/username/task-manager',
    demoLink: 'https://username.github.io/task-manager',
  },
  {
    title: 'Blog Platform',
    subTitle: 'Content Management System',
    description: 'A blog platform where users can create, edit, and delete posts, and leave comments.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'React', 'Node.js', 'Express', 'MongoDB'],
    codeLink: 'https://github.com/username/blog-platform',
    demoLink: 'https://username.github.io/blog-platform',
  },
  {
    title: 'Chat Application',
    subTitle: 'Real-Time Messaging',
    description: 'A real-time chat application that allows users to send and receive messages instantly.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'React', 'Socket.io', 'Node.js'],
    codeLink: 'https://github.com/username/chat-application',
    demoLink: 'https://username.github.io/chat-application',
  },
  {
    title: 'Expense Tracker',
    subTitle: 'Budget Management',
    description: 'An expense tracker app to help users manage their finances by tracking income and expenses.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'Angular'],
    codeLink: 'https://github.com/username/expense-tracker',
    demoLink: 'https://username.github.io/expense-tracker',
  },
  {
    title: 'Recipe Finder',
    subTitle: 'Cooking and Recipe App',
    description: 'A recipe finder app that provides users with recipes based on ingredients they have on hand.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'React'],
    codeLink: 'https://github.com/username/recipe-finder',
    demoLink: 'https://username.github.io/recipe-finder',
  },
  {
    title: 'Fitness Tracker',
    subTitle: 'Health and Fitness',
    description: 'A fitness tracker app to help users track their workouts, progress, and health metrics.',
    technologies: ['HTML', 'CSS', 'JavaScript'],
    codeLink: 'https://github.com/username/fitness-tracker',
    demoLink: 'https://username.github.io/fitness-tracker',
  },
  {
    title: 'Social Media Platform',
    subTitle: 'Networking and Community',
    description: 'A social media platform that allows users to connect, share posts, and interact with each other.',
    technologies: ['HTML', 'CSS', 'JavaScript', 'React', 'Node.js', 'Express', 'MongoDB'],
    codeLink: 'https://github.com/username/social-media-platform',
    demoLink: 'https://username.github.io/social-media-platform',
  },
];
