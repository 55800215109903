import styled from "styled-components";
import SkillTags from "../../../components/SkillTags";

const StyledSkillTagsWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const skills = [
  'React.js', 'Node.js', 'JavaScript', 'TypeScript', 'MangoDB', 'HTML', 'CSS', 'Laravel', 'Git', 'Bootstrap', 'Tailwind CSS', 'Material UI', 'Ant Designs', 'Styled Components', 'SCSS', 'Redux', 'MobX', 'React Query'
]

const RightContent = () => {
  return (
    <StyledSkillTagsWrapper className="asd">
      <SkillTags skills={skills} />
    </StyledSkillTagsWrapper>
  )
}

export default RightContent