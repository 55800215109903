import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledMain = styled.main`
  flex-grow: 1;
  overflow: hidden;
`;

const Main = (props: PropsWithChildren) => {
  return (
    <StyledMain>
      {props.children}
    </StyledMain>
  )
}

export default Main