import React from 'react'
import { Outlet } from 'react-router';
import styled from 'styled-components';

const StyledLeftContent = styled.div`
  padding: 10px 10px;
  height: 100%;
  width: 100%;
`;

const LeftContent = () => {
  return (
    <StyledLeftContent>
      <Outlet />
    </StyledLeftContent>
  )
}

export default LeftContent