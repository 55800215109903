import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
`

export const StyledInstructionsWrapper = styled.div`
  width: 220px;
  height: 440px;
  flex-shrink: 0;
`

export const StyledButton = styled.button`
   padding: 10px 20px;
   outline: 1px solid #1E2D3D;
   border: none;
   border-radius: 8px;
   color: #FFF;
   background-color: #010C15;
   margin: 4px;
`

export const StyledInstructions = styled.div`
  border-radius: 8px;
  background: rgba(1, 20, 35, 0.19);
  color: #fff;
   padding: 10px;
  gap:10px;
`

export const StyledCanvasWrapper = styled.div`
  position: relative; 
  height: 440px;
  width: 340px;
  background: rgba(1, 22, 39, 0.84);
  box-shadow: inset 1px 5px 11px rgba(2, 18, 27, 0.71);
  border-radius: 8px;
`

export const StyledGameOverWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background: rgba(1, 36, 39, 0.60);
`

export const StyledGameOver = styled.div`
  position: absolute;
  width: 100%;
  padding: 12px 0;
  font-size: 32px;
  left: 0;
  bottom: 100px;
  color: #29f5b4ed;
  background: rgba(1, 22, 39, 0.84);
`

export const StyledCanvas = styled.canvas`
  height: 440px;
  width: 340px;
`

export const StyledPlayAgainWrapper = styled.div`
  position: absolute;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  padding: 12px 0;
  font-size: 14px;
  left: 0;
  bottom: 50px;
  color: #cbffe6;
`

export const StyledPlayAgainButton = styled.button`
  border: none;
  outline: none;
  background: none;
  color: #c3fffd;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
`

export const StyledInstructionTextWrapper = styled.div`
  text-align: left;
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;

`

export const StyledFoodWrapper = styled.div`
  color: #fff;
  text-align: left;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
`

export const FoodContainer = styled.div`
  display: grid;
  grid-template-columns: 22px 22px 22px 22px 22px;
  column-gap: 16px;
  row-gap: 16px;
`

export const StyledFood = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #42d8ab4b;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    height: 16px;
    width: 16px;
    background-color: #42d8ab9f;
    border-radius: 50%;
  }

  &:after{
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    height: 10px;
    width: 10px;
    background-color: #42d8ab;
    border-radius: 50%;
  }
`
export const StyledFoodEaten = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #43d9ac10;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    height: 16px;
    width: 16px;
    background-color: #43d9ac25;
    border-radius: 50%;
  }

  &:after{
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    height: 10px;
    width: 10px;
    background-color: #43d9ac40;
    border-radius: 50%;
  }
`


export const StyledScrew = styled.div`
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 50%;EERZ
  background: rgb(2,32,40);
background: radial-gradient(circle, #044759e1 0%, #04313fe1 60%, #0d476ef1 100%);
box-shadow: 2px 2px 6px 0px rgba(9, 34, 37, 0.8);
 
  &.top-left {
    top: 14px;
    left: 14px;
  }
  &.top-right {
    top: 14px;
    right: 14px;
  }
  &.bottom-left {
    bottom: 14px;
    left: 14px;
  }
  &.bottom-right {
    bottom: 14px;
    right: 14px;
  }

  .line-one{
    position: absolute;
    top: 4px;
    left: 6px;
    height: 6px;
    width: 2px;
    border-radius: 1px;
    background-color: #011627;
    transform: rotate(-45deg)
  }

  .line-two{
    position: absolute;
    top: 6px;
    left: 4px;
    height: 2px;
    width: 6px;
    border-radius: 1px;
    background-color: #011627;
    transform: rotate(-45deg)
  }
`