import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { INavChild, INavData, StyledInfoIcon } from '.'
import { ThemeProps } from '../../../@types/theme'

const StyledPersonalItem = styled.a`
  display: flex;
  align-items: center;
  padding: 4px 15px;
  &:hover{
    background-color:  ${({ theme }: { theme: ThemeProps }) => theme.colors.primary2};
    cursor: pointer;
  }
`

const StyledPersonalItemChild = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 4px 0;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  padding-left: 50px;
  text-decoration: none;
  &:visited{
    color: inherit;
  }
  &:hover{
    color:  ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
    cursor: pointer;
  }
  &.active {
    color:  ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2};
  }
`

const StyledDropIcon = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
`

interface IProps {
  nav: INavData;
  setActiveNav: (nav: string) => void;
}

const SidebarItem = (props: IProps) => {
  const { nav, setActiveNav } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);

  const navigate = useNavigate()

  useEffect(() => {
    if (nav.name === "bio") {
      setIsOpen(true)
      navigate('bio/introduction')
      setActiveNav('introduction')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav.name])

  return (
    <>
      <StyledPersonalItem onClick={toggle}>
        <StyledDropIcon>
          <i className={isOpen ? "ri-arrow-down-s-line" : 'ri-arrow-right-s-line'}></i>
        </StyledDropIcon>
        <StyledInfoIcon className={nav.iconClass}>
          <i className={nav.icon}></i>
        </StyledInfoIcon>
        {nav.name}
      </StyledPersonalItem>
      {
        isOpen && nav.children?.map((child: INavChild) => (
          <StyledPersonalItemChild to={child.path}
            className={({ isActive }) => (isActive ? "active" : "")} onClick={() => setActiveNav(child.name)}>
            <StyledInfoIcon>
              <i className={child.icon}></i>
            </StyledInfoIcon>
            {child.name}
          </StyledPersonalItemChild>
        ))
      }
    </>
  )
}

export default SidebarItem