import React from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../../../@types/theme';
import SidebarItem from './SidebarItem';
import { useToggle } from '../../../hooks/useToggle';

const StyledSideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  width: 240px;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;

  @media (max-width: 500px){
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
`;

const StyledSideNabTitle = styled.span`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
  display: flex;
  gap: 4px;
  align-items: center;
`

const StyledSideNabIcon = styled.span`
  font-size: 20px;
`

const StyledPersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
`

const StyledContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`
const StyledBlockHeader = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: inherit;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};

  :hover{
    cursor: pointer;
  }
`

const StyledContactInfoItem = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 15px;
`

export const StyledInfoIcon = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 10px;

  &.accent1{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent1}
  }

  &.accent2{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2}
  }

  &.accent3{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3}
  }
`

export interface INavData {
  name: string;
  icon: string;
  iconClass: string;
  path?: string;
  children?: INavChild[]
}

export interface INavChild {
  name: string;
  icon: string;
  path: string;
}

const navData: INavData[] = [
  {
    name: "bio",
    icon: "ri-folder-user-fill",
    iconClass: 'accent1',
    children: [
      {
        name: 'introduction',
        icon: "ri-file-code-fill",
        path: 'bio/introduction'
      }
    ]
  },
  {
    name: "education",
    icon: "ri-folder-user-fill",
    iconClass: 'accent2',
    children: [
      {
        name: 'bachelor',
        icon: "ri-file-code-fill",
        path: 'education/bachelor'
      },
      {
        name: 'highSchool',
        icon: "ri-file-code-fill",
        path: 'education/high-school'
      },
      {
        name: 'slc',
        icon: "ri-file-code-fill",
        path: 'education/slc'
      }
    ]
  },
  {
    name: "workHistory",
    icon: "ri-folder-user-fill",
    iconClass: 'accent3',
    children: [
      {
        name: 'infoDevelopers',
        icon: "ri-file-code-fill",
        path: 'work/info-dev  '
      },
      {
        name: 'alphaBetaTheta',
        icon: "ri-file-code-fill",
        path: 'work/abt'
      },
      {
        name: 'greenComputing',
        icon: "ri-file-code-fill",
        path: 'work/green-computing'
      }
    ]
  }
]

interface IProps {
  setActiveNav: (nav: string) => void;
}


const AboutSideBar = (props: IProps) => {
  const { setActiveNav } = props

  const [isPersonalInfoOpen, togglePersonalInfo] = useToggle(true);
  const [isContactInfoOnen, toggleContactInfo] = useToggle(true);

  return (
    <>
      <StyledSideNavWrapper>
        <StyledBlockHeader onClick={togglePersonalInfo}>
          <StyledSideNabTitle>
            {isPersonalInfoOpen ? (
              <StyledSideNabIcon>
                <i className="ri-arrow-down-s-fill"></i>
              </StyledSideNabIcon>
            ) : (
              <StyledSideNabIcon>
                <i className="ri-arrow-right-s-fill"></i>
              </StyledSideNabIcon>
            )}
            personal-info
          </StyledSideNabTitle>
        </StyledBlockHeader>
        {isPersonalInfoOpen && (
          <StyledPersonalInfoWrapper>
            {
              navData.map((nav: INavData) => (
                <SidebarItem nav={nav} setActiveNav={setActiveNav} />
              ))
            }
          </StyledPersonalInfoWrapper>
        )}
        <StyledBlockHeader onClick={toggleContactInfo}>
          <StyledSideNabTitle>
            {isContactInfoOnen ? (
              <StyledSideNabIcon>
                <i className="ri-arrow-down-s-fill"></i>
              </StyledSideNabIcon>
            ) : (
              <StyledSideNabIcon>
                <i className="ri-arrow-right-s-fill"></i>
              </StyledSideNabIcon>
            )}
            contacts
          </StyledSideNabTitle>
        </StyledBlockHeader>
        {isContactInfoOnen && (
          <StyledContactInfoWrapper>
            <StyledContactInfoItem>
              <StyledInfoIcon>
                <i className="ri-mail-fill"></i>
              </StyledInfoIcon>
              bhattaraibikash55
            </StyledContactInfoItem>
            <StyledContactInfoItem>
              <StyledInfoIcon>
                <i className="ri-phone-fill"></i>
              </StyledInfoIcon>
              +977 9849940443
            </StyledContactInfoItem>
          </StyledContactInfoWrapper>
        )}
      </StyledSideNavWrapper>
    </>
  )
}

export default AboutSideBar