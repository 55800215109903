import { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { ContactFormData, initialFormData } from '.'
import { ThemeProps } from '../../../@types/theme'

const StyledFormWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
`

const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  gap: 5px;
  width: 100%;
  max-width : 400px;
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const StyledButton = styled.button`
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 8px 16px;
  background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefault};
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefaultText};

  &:hover,:focus {
    cursor: pointer;
    transition: .3s;
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefaultHover};
  }
`

const StyledMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
`

const StyledThankYou = styled.p`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
  font-size: 1.8rem;
`

const StyledMessageText = styled.p`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  max-width: 400px;
  text-align: center;
`

interface IForm {
  formData: ContactFormData;
  setFormData: any;
}

const Form = (props: IForm) => {
  const { formData, setFormData } = props;

  const [showForm, setShowForm] = useState<boolean>(true);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevState: ContactFormData) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(formData, 'Values');
    setShowForm(false);
  }

  const handleSendNew = () => {
    setFormData(initialFormData);
    setShowForm(true);
  }

  return (
    <StyledFormWrapper>
      {showForm ? (
        <StyledForm onSubmit={e => { handleSubmit(e) }}>
          <StyledInputGroup>
            <label>_name:</label>
            <input name='_name' id="_name" value={formData._name} onChange={handleOnChange} />
          </StyledInputGroup>
          <StyledInputGroup>
            <label>_email:</label>
            <input name='_email' id="-_email" value={formData._email} onChange={handleOnChange} />
          </StyledInputGroup>
          <StyledInputGroup>
            <label>_message:</label>
            <textarea rows={4} name='_message' id="_message" value={formData._message} onChange={handleOnChange} />
          </StyledInputGroup>
          <StyledInputGroup>
            <StyledButtonWrapper>
              <StyledButton type='submit'>
                submit-message
              </StyledButton>
            </StyledButtonWrapper>
          </StyledInputGroup>
        </StyledForm>
      ) : (
        <StyledMessageWrapper>
          <StyledThankYou>Thank You!🤘</StyledThankYou>
          <StyledMessageText>Your message has been accepted. You will recieve answer really soon!</StyledMessageText>
          <StyledButton type='button' onClick={handleSendNew}>
            send-new-message
          </StyledButton>
        </StyledMessageWrapper>
      )}
    </StyledFormWrapper>
  )
}

export default Form